@import "~antd/dist/antd.css";
@import "@ant-design/pro-table/dist/table.css";

.App {
  text-align: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mainBox {
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
}

.paginationBox {
  text-align: right;
  padding: 20px 0;
}

.mainCard {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);

  &:last-child {
    margin-bottom: 0;
  }

  .cardHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid #e8e8e8;

    &.headerBetween {
      justify-content: space-between;
    }

    .cardHeaderBtn {
      .ant-btn + .ant-btn {
        margin-left: 15px;
      }

      .ant-popover-disabled-compatible-wrapper + .ant-btn {
        margin-left: 15px;
      }

      .ant-popover-disabled-compatible-wrapper
        + .ant-popover-disabled-compatible-wrapper {
        margin-left: 15px;
      }

      .excel-img {
        width: 20px;
        margin-right: 4px;
      }
    }

    &.cardSelect {
      justify-content: flex-start;

      .ant-select {
        width: 180px;
      }
    }
  }

  .cardBody {
    padding: 16px 24px;

    .searchForm {
      margin: 0 -8px -16px !important;
    }

    .tableHeader {
      display: flex;
      justify-content: space-between;
      align-content: center;
      line-height: 32px;

      p,
      .ant-btn {
        margin-bottom: 0 !important;
      }
    }

    &.cardTableBody {
      padding: 0;
    }
  }

  &.editCard {
    .cardBody {
      padding: 32px 24px;

      .ant-btn + .ant-btn {
        margin-left: 15px;
      }

      .btnCenter {
        text-align: center;
        padding-top: 24px;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-thead > tr > th {
  background: #f8f7fa !important;
}

.ant-modal-content {
  z-index: 100000;
}

.ant-btn {
  min-width: 80px !important;
  padding: 4px 10px !important;

  &.ant-btn-sm {
    min-width: 24px !important;
    padding: 0 7px !important;
  }
}

.ant-tabs-nav {
  margin-bottom: 0 !important;

  .ant-tabs-tab {
    margin: 0 0 0 24px;
    padding: 15px 0;
  }
}

.ant-tabs-extra-content {
  padding-right: 24px;
}

.ant-table-pagination {
  margin-bottom: 0 !important;
}

.rightArea {
  text-align: right;
  min-width: 976px;
}

.dashboardCard {
  .cardHeader {
    justify-content: space-between;
    align-items: center;
    height: 57px;
    overflow: hidden;
    border-bottom: 1px solid #f0f0f0;

    .time {
      color: darkred;
      margin: 0;
    }

    .ant-select {
      width: 260px !important;

      .ant-select-selector {
        background: none !important;
        box-shadow: none !important;
      }

      &.dataSel {
        width: 126px !important;
      }

      &.multipleSelect {
        .ant-select-selection-item {
          color: #333;
          //background: none;

          .anticon-close {
            color: #333;
          }
        }
      }
    }

    .ant-picker {
      background: none;
    }

    .ant-form-inline .ant-form-item-with-help,
    .ant-form-inline .ant-form-item-has-error {
      height: 32px;
      overflow: hidden;
      margin-bottom: 0 !important;

      .ant-select-selection-placeholder {
        color: #ff4d4f;
      }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      display: none;
      position: absolute;
      left: 11px;
      top: 5px;
    }
  }

  .cardBody {
    padding: 24px;

    .cardLoading {
      .ant-spin .ant-spin-text {
        text-shadow: none !important;
      }

      .ant-spin-container::after {
        background: transparent;
      }
    }

    .dashboardBox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 0 0;
    }

    .dashboardImg {
      text-align: center;
      font-size: 16px;
      max-width: 460px;

      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        color: darkred;
        margin: 0 0;

        .live {
          flex: 1;
          text-align: right;
          color: #ff0000;
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 460px;
        max-height: 420px;
        border-radius: 10px;
        margin: 0 auto;
      }
    }

    .echartsBox {
      text-align: center;

      .echarts-for-react {
        width: 100%;
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
      }
    }

    h2 {
      font-size: 20px;

      .subTtl {
        font-size: 12px;
      }
    }

    .ant-statistic {
      .ant-statistic-content {
        font-size: 32px;
        font-weight: bold;

        .ant-statistic-content-suffix {
          font-size: 24px;
        }

        .ant-statistic-content-prefix {
          color: #ff0000;
        }
      }

      &.man {
        .ant-statistic-content-prefix {
          color: #1890ff;
        }
      }
    }

    .gender {
      margin-bottom: 16px;

      .ant-statistic {
        &.all {
          display: inline-block;

          .ant-statistic-content {
            font-size: 18px;
          }
        }
      }
    }

    .annual {
      margin-bottom: 36px;
    }

    .data {
      margin-top: 36px;

      /*.echartsBox {
        text-align: left;

        .echarts-for-react {
          margin: 0;
        }
      }*/
    }

    .customerInfo {
      padding-top: 36px;

      .ant-statistic {
        .ant-statistic-title {
          text-align: center;
        }

        .ant-statistic-content {
          text-align: center;
        }
      }
    }

    .watch {
      padding-top: 36px;
    }
  }
}

.reportCard {
  .cardHeader {
    .ant-btn + .ant-btn {
      margin-left: 15px;
    }
  }

  .cardBody {
    padding: 24px;

    h2 {
      color: #333;
      font-size: 18px;

      .subRedTtl {
        color: #ff0000;
      }

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 16px;
        margin-right: 5px;
        margin-bottom: 2px;
        border-radius: 2px;
        background: #333;
      }
    }

    .echartsBox {
      text-align: center;
      padding-bottom: 36px;

      &:last-child {
        padding: 0;
      }
    }

    .ant-statistic {
      .ant-statistic-title {
        color: #333;
      }

      .ant-statistic-content {
        color: #333;

        .ant-statistic-content-prefix {
          color: #ff0000;
        }
      }

      &.man {
        .ant-statistic-content-prefix {
          color: #1890ff;
        }
      }
    }

    .gender {
      margin-bottom: 36px;
    }
  }
}

.redText {
  color: #ff0000;
}

.ant-pagination-total-text,
.ant-pagination-options-quick-jumper {
  display: none !important;
}

.ant-input {
  &.site-input-left {
    width: 100px;
    border-right: 0;
    text-align: center;
  }

  &.site-input-right {
    width: 100px;
    border-left: 0;
    text-align: center;
  }

  &.site-input-split {
    width: 30px;
    border-left: 0;
    border-right: 0;
    pointer-events: none;
    background: #fff;
  }
}

.ant-card-head {
  padding: 0 10px !important;
  background: #fafafa !important;
}

.ant-card-head-title,
.ant-collapse-header {
  font-size: 20px !important;
  font-weight: 500;
  padding: 7px 9px !important;
}

.strong-header > .ant-card-head,
.ant-collapse-header {
  background: #f2f2f2 !important;
}

.ant-card-body {
  padding: 10px !important;

  .echartsBox {
    text-align: center;

    .echarts-for-react {
      margin: 0 auto;
    }

    &.radarBox {
      background: url("./imgs/AVS.jpg") no-repeat center top;
      background-size: auto 350px;

      .echarts-for-react {
        background: transparent;
      }
    }

    &.report {
      background: url("./imgs/AVS.jpg") no-repeat center top;
      background-size: auto 300px;

      .echarts-for-react {
        background: transparent;
      }
    }
  }
}

.ant-form-item-label {
  width: auto;
}

.ant-input-number-handler-wrap {
  display: none;
}

.gender-num {
  .ant-statistic-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;

    .ant-statistic-content-prefix {
      flex: 1;
      text-align: left;

      svg {
        display: block;
      }
    }

    .ant-statistic-content-value {
      flex: 1;
      text-align: center;
    }

    .ant-statistic-content-suffix {
      flex: 1;
      text-align: right;
    }
  }
}

.exportConditionTable {
  padding: 0 !important;
  margin-bottom: 20px;
}

.exportConditionTableSpaceHide {
  display: none;
}

.exportConditionTableSpace1 {
  @media print {
    height: 190px;
  }
}

.exportConditionTableSpace2 {
  @media print {
    height: 225px;
  }
}

.print_btn {
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s;
  background: url("./imgs/print.png") repeat;
  background-size: 78px 28px;
  width: 80px;
  height: 32px;
}

.label_require {
  .ant-form-item-label > label::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}
